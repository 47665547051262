import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Auth from './components/Auth/Auth';
import App from './App';
import InitiateSSO from './components/Auth/InitiateSSO';
import Cryptography from './components/Auth/Cryptography';
import InitiateAuthLookup from './components/Auth/InitiateAuthLookup';
import TimeoutPage from './components/Timeout/timeoutpage.component';
import UnauthorizedPage from './components/Verification/unauthorizedpage.component';
import IdleSessionHandler from './components/Timeout/idlesessionhandler.component';
import { AuthGuard } from './guards/authguard';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './services/appInsights.service';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <AppInsightsContext.Provider value={reactPlugin}>
      <BrowserRouter>
        <Routes>
          <Route path="/auth" element={<Auth />} />
          <Route path="/sso" element={<InitiateSSO cryptoGraphy={new Cryptography()} />} />
          <Route path="/callback/:authId" element={<InitiateAuthLookup cryptoGraphy={new Cryptography()} />} />
          <Route path="/" element={
            <AuthGuard>
              <IdleSessionHandler>
                <App />
              </IdleSessionHandler>
            </AuthGuard>
          } />
          <Route path="/timeoutpage" element={<TimeoutPage />} />
          <Route path="/unauthorizedpage" element={<UnauthorizedPage />} />
        </Routes>
      </BrowserRouter>
    </AppInsightsContext.Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();