export const getAccessCode = async(challenge:string, authId: string) => {
  const accessToken = sessionStorage.getItem('accessToken');
  if (!accessToken) {
    throw new Error('Error retrieving Access Token');
  }
  let jwt;
  try {
    const response = await fetch(`${process.env.api_url}/User/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${accessToken}`,
      },
      body: JSON.stringify({
        authId
      })
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data: TokenResponse = await response.json();
    jwt = data.jwt;
  } catch{
    throw new Error('Error Fetching Token');
  }

  try {
    const response = await fetch(`${process.env.cw_ui_base_url}${process.env.cw_accesscode_path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        key:"GetAccessCode",
        data: {
          CodeChallenge:challenge,
          Token:jwt  
        }
      }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.text();
    return data;
  } catch{
    throw new Error('Error Fetching AccessCode');
  }
}