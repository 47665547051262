import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

interface HeaderMenuProps {
    userAuthorized?: boolean
}

const HeaderMenu: React.FC<HeaderMenuProps> = ({ userAuthorized = false }) => {

    return (
        <div>
            {userAuthorized ? (
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <Link to={`${process.env.evicore_url}`} className="nav-link" data-testid="authLookupLink">
                            <FontAwesomeIcon icon={faMagnifyingGlass} className="pr-2" />
                            Auth Lookup
                        </Link>
                    </li>
                </ul>
            ) : <div></div>
            }

        </div>
    );
};

export default HeaderMenu;