import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: `${process.env.app_insights_connection}`,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true, 
    disableInstrumentationKeyValidation:false, 
  }
});
appInsights.loadAppInsights();

export { reactPlugin, appInsights };