import React, { useEffect, useState } from 'react'
import { useIdleTimer, } from 'react-idle-timer'
import { useNavigate, useLocation } from 'react-router-dom'
import * as styles from './idlesessionhandler.module.css'
import { revokeSession } from '../../services/timeout.service';

interface IdleSessionHandlerProps {
    children: React.ReactNode;
    timeout?: number;
    promptBeforeIdle?: number
}

const IdleSessionHandler: React.FC<IdleSessionHandlerProps> = ({ children, timeout = 20 * 60 * 1000, promptBeforeIdle = 15 * 60 * 1000 }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [showModel, setShowModel] = useState(false);
    const [remainingTime, setRemainingTime] = useState<string>('0:00')
    const exemptedRoutes = ['/auth', '/sso', 'timeoutpage', '/unauthorizedpage']
    const isExemptedRoute = exemptedRoutes.includes(location.pathname)
    const isAuthenticated = sessionStorage.getItem('accessToken')
    if (!isAuthenticated || isExemptedRoute) {
        return <>{children}</>
    }

    const idleTimer = useIdleTimer({
        timeout: timeout,
        promptBeforeIdle: promptBeforeIdle,
        onPrompt: () => {
            setShowModel(true)
            updateRemainingTime();
        },
        onIdle: async () => {
            await revokeSession();
            sessionStorage.clear();
            navigate('./timeoutpage')
        },
        debounce: 500,
        disabled: !isAuthenticated || isExemptedRoute
    })
    const { getRemainingTime, reset } = idleTimer;

    const updateRemainingTime = () => {
        const time = getRemainingTime();
        const totalSecond = Math.ceil(time / 1000);
        const minutes = Math.floor(totalSecond / 60);
        const seconds = totalSecond % 60;
        const formattedTime = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        setRemainingTime(formattedTime)
    }
    useEffect(() => {
        let intervalId: NodeJS.Timeout;
        if (showModel) {
            intervalId = setInterval(() => {
                updateRemainingTime();
            }, 1000);
        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId)
            }
        }
    }, [showModel])

    const handleEndSession = async () => {
        reset();
        sessionStorage.clear();
        await revokeSession();
        setShowModel(false);
        navigate('./timeoutpage')
    }

    const handleExtendSession = () => {
        reset();
        setShowModel(false);
        //ExtendSession Api Call here
    }
    return (
        <>
            {children}
            {showModel && (
                <div className={`modal show && ${styles.modal}`} tabIndex={-1} role='dialog'>
                    <div className='modal-dialog' role='document'>
                        <div className='modal-content'>

                            <div className='modal-header'>
                                <h5 className='modal-title'>Session Expiration</h5>
                                <button type='button' className='close' onClick={handleExtendSession}>
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div className='modal-body'>
                                <p>Your Session will expire in {remainingTime} minutes. Do you want to Extend your session?</p>
                            </div>
                            <div className='modal-footer'>
                                <button type='button' className='btn btn-danger' onClick={handleEndSession}>End Session</button>
                                <button type='button' className='btn btn-primary' onClick={handleExtendSession}>Extend Session</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
export default IdleSessionHandler;