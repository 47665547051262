import { FC, ReactNode } from "react";
import { useLocation, Navigate } from "react-router-dom";

interface AuthGuardProps {
    children: ReactNode;
}

export const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
    const location = useLocation();
    const auth = sessionStorage.getItem('accessToken');
    if (!auth) {
        return <Navigate to="/unauthorizedpage" state={{ from: location }} replace />
    }
    return <>{children}</>
}