// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rGlngPOEai9vyxbd5262 {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}
`, "",{"version":3,"sources":["webpack://./src/components/Timeout/idlesessionhandler.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oCAAoC;AACtC","sourcesContent":[".modal {\n  display: block;\n  background-color: rgba(0, 0, 0, 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
export var modal = `rGlngPOEai9vyxbd5262`;
export default ___CSS_LOADER_EXPORT___;
