import React from 'react';
import Header from './components/Header/header.component';
import RequestDashboard from './modules/RequestDashboard/RequestDashboard';


const App: React.FC = () => {
    return (

        <div>
            <Header />
            <main>
                <RequestDashboard />
            </main>
        </div>
    );
};

export default App;