import React, { useEffect } from 'react';
import { ICryptography } from "./ICryptography";
import { useSearchParams, useParams } from 'react-router-dom';
import { getAccessCode } from '../../services/token.service';

type CallBackServiceProps = {
    cryptoGraphy:ICryptography
};

//AuthId is needed when we actually redirect to CW
async function handleRedirect(challenge:string, authId: string) {
  const accessCode = await getAccessCode(challenge, authId);
  if (accessCode) {
    const url = `${process.env.cw_ui_base_url}${process.env.cw_redirect_path}?code=${accessCode}`
    window.location.replace(url);
  }
} 

//In the future this should be wrapped in a suspense component that contains a loading spinner
//We should also load some sort of backgound in
//eslint-disable-next-line
const InitiateAuthLookup: React.FC<CallBackServiceProps> = (props: CallBackServiceProps) => {

    const [searchParams] = useSearchParams();
    const challenge = searchParams.get('challenge') || '';
    const { authId = '' } = useParams();
    
    useEffect(() => {
      handleRedirect(challenge, authId);
    }, [])

    return (
        <div>Redirecting...</div>
    );
};

export default InitiateAuthLookup;
