import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "./appInsights.service";

export const revokeSession = async () => {
  const accessToken = sessionStorage.getItem('accessToken');
  if (accessToken) {
    try {
      const response = await fetch(`${process.env.api_url}/User/Session`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${accessToken}`,
        },
      });

      if (response.ok) {
        appInsights.trackTrace({ message: 'Revoke Session - Successfully called user session delete', severityLevel: SeverityLevel.Information, properties: { response: JSON.stringify(response) } });
      }
      else {
        appInsights.trackTrace({ message: 'Revoke Session - UNSUCCESSFULLY called user session delete', severityLevel: SeverityLevel.Information, properties: { response: JSON.stringify(response) } });
        throw new Error('Network response was not ok');
      }
      sessionStorage.removeItem('accessToken');
      return response.ok;
    } catch (error) {
      if (error instanceof Error) {
        appInsights.trackException({ exception: error });
      }

      throw new Error('Error Revoking token');
    }
  }
};
